<template>
    <div class="fl-x-res fl-a-c py-3" :class="{'line-bottom':lineBottom}">
        <div class="notif-icon-contain border-r-1 bg-1 border-rounded text-center fl-x-cc mb-3 mb-lg-0 mr-3">
            <img src="../../assets/web/dashboard/logos/logo.png" alt="" class="notif-icon-img">
        </div>
        <div class="text-center-res">
            <p class="font-inter-semi-bold mb-0 fs--1">{{ data.heading }}</p>
            <p class="font-inter-regular mb-0 fs-lg--2">{{data.message}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name  : 'NotificationCard',
    props : {
        data : { type : Object },

        lineBottom : {
            type    : Boolean,
            default : false
        }
    }
};
</script>

<style scoped lang="scss">
.border-rounded {
    border-radius: 50%;
}
.w-7p {
    width: 7%;
}

.line-bottom{
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        //left: -16px;
        left: 0;
        right: 0px;
        height: 1px;
        width: 105%!important;
        background-color: rgba(128, 128, 128, 0.09);
    }
}
.notif-icon-contain {
    height: 2.5rem !important;
    width: 3rem !important;
    //background-color: #ebebed !important;
    //background-color: #ededef !important;
    background-color: rgba(237, 237, 239, 0.6);
}
.notif-icon-img {
    /*height: 1.3rem !important;*/
    height: 0.95rem !important;
}
</style>
