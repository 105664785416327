<template>
    <div class="fl-te-c-res py-3 px-3 border-r-1 line-bottom-100">
        <notification-card :data="data"></notification-card>

        <div class="b-1 px-3 py-2 border-r-3 text-center font-inter-light">{{ getTimeLeft() }}</div>

    </div>
</template>

<script>
import NotificationCard from '@components/dashboard/NotificationCard';

export default {
    props : {
        data : { type : Object }
    },

    name : 'NotificationItem',

    components : { NotificationCard },

    methods : {
        getTimeLeft () {
            if (this.data.time_left_day) {
                return this.data.time_left_day + ' Day ago';
            } else if (this.data.time_left_hour) {
                return this.data.time_left_hour + ' Hour ago';
            } else if (this.data.time_left_min) {
                return this.data.time_left_min + ' Min ago';
            } else {
                return this.data.time_left_sec + ' Sec ago';
            }
        }
    }
};
</script>

<style scoped>

</style>
