<template>
    <inner-page-card heading="Notifications" :horz-padding="false">
        <div v-if="dataLoading" class="text-center pt-3">
            <loading-animation></loading-animation>
        </div>
        <div class="row" v-if="data.length!==0">
            <div class="col-12" v-for="i in data" :key="i">
                <notification-item :data="i"></notification-item>
            </div>
        </div>
        <p v-if="!dataLoading && data.length===0" class="text-center font-inter-regular mb-0 fs-lg--1 pt-3">No new
            notifications.</p>
    </inner-page-card>
</template>

<script>
import NotificationItem from '@components/notifications/NotificationItem';
import axios from 'secure-axios';
import urls from '../data/urls';

export default {
    name : 'NotificationsPage',

    components : { NotificationItem },

    data () {
        return {
            dataLoading : false,
            data        : ''
        };
    },

    mounted () {
        this.loadData();
    },

    methods : {
        async loadData () {
            this.dataLoading = true;
            const response = await axios.get(urls.auth.notifications);
            const json = response.data;
            this.data = json.data;
            this.dataLoading = false;
        }
    }
};
</script>

<style scoped>

</style>
